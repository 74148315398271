<template>
    <div :class="['my-1', { 'my-3': editMode }]">
        <div class="mb-2" v-if="editMode">Rate out of 5 stars</div>
        <div @mouseenter="hoverMode = true" @mouseleave="hoverMode = false">
            <template v-for="i in max">
                <i
                    :key="i"
                    :class="[
                        'rating-star',
                        'rating-star-active',
                        'fa',
                        'fa-star',
                        { 'fa-2x': editMode },
                        'mr-1',
                    ]"
                    v-if="i <= showRating"
                    @mouseover="updateRatingStar(i)"
                    @click="setRatingStar(i)"
                />
                <i
                    :key="i"
                    :class="[
                        'rating-star',
                        'fa',
                        'fa-star-o',
                        { 'fa-2x': editMode },
                        'text-secondary',
                        'mr-1',
                    ]"
                    v-else
                    @mouseover="updateRatingStar(i)"
                    @click="setRatingStar(i)"
                />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rating: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 5,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hoverMode: false,
            hoverRating: 0,
        }
    },
    methods: {
        updateRatingStar(i) {
            if (this.editMode) {
                this.hoverRating = i + 1
            }
        },
        setRatingStar(i) {
            this.$emit("update", i + 1)
        },
    },
    computed: {
        showRating() {
            if (this.hoverMode && this.editMode) {
                return this.hoverRating
            } else {
                return this.rating
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.rating-star {
    cursor: pointer;

    &.rating-star-active,
    &:hover {
        color: gold;
    }
}
</style>
