<template>
    <div class="material-tester-container">
        <PageLoader :isLoading="isLoading" />
        <div class="h5 mb-4">Available Material Inspectors</div>
        <template v-for="(materialTester, i) in materialTesters">
            <MaterialTesterItem
                :productId="productId"
                :materialTester="materialTester"
                :key="i"
                @update="updateMaterialTester($event, i)"
            />
        </template>
        <div v-if="!isLoading && materialTesters && materialTesters.length === 0">
            No Material Testers Found for this product
        </div>
        <Paginator
            :page="page"
            :itemsPerPage="itemsPerPage"
            :totalResults="totalResults"
            @update="pageUpdate"
        />
    </div>
</template>

<script>
import MaterialTesterItem from "./MaterialTesterItem"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"
import Paginator from "../utils/Paginator.vue"
import PageLoader from "../Loader.vue"

export default {
    components: { MaterialTesterItem, Paginator, PageLoader },
    props: {
        productId: {
            type: Number,
        },
    },
    data() {
        return {
            materialTesters: null,
            totalResults: 0,
            itemsPerPage: 4,
            page: 1,
            isLoading: false,
        }
    },
    async mounted() {
        scrollTop()
        // on initial page update this method is called in pageUpdate() function, check there.
        // await this.fetchMaterialTesters()
    },
    methods: {
        async fetchMaterialTesters() {
            this.isLoading = false
            try {
                const res = await request.get(
                    "/material-testers?product_id=" +
                        this.productId +
                        "&page=" +
                        this.page +
                        "&limit=" +
                        this.itemsPerPage
                )
                const data = res.data
                this.materialTesters = data.result
                this.totalResults = data.totalResults

                console.log(data)
            } catch (err) {
                this.isLoading = true
            }
        },
        async updateMaterialTester(newMaterialTester, index) {
            const materialTesters = [...this.materialTesters]
            materialTesters[index] = newMaterialTester
            this.materialTesters = materialTesters
        },
        async pageUpdate(pageData) {
            const { page, totalPages } = pageData
            if (page) {
                this.page = page
                await this.fetchMaterialTesters()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.material-tester-container {
    margin: 20px auto;
    margin-top: 50px;
    max-width: 900px;
}
</style>
