var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['my-1', { 'my-3': _vm.editMode }]},[(_vm.editMode)?_c('div',{staticClass:"mb-2"},[_vm._v("Rate out of 5 stars")]):_vm._e(),_c('div',{on:{"mouseenter":function($event){_vm.hoverMode = true},"mouseleave":function($event){_vm.hoverMode = false}}},[_vm._l((_vm.max),function(i){return [(i <= _vm.showRating)?_c('i',{key:i,class:[
                    'rating-star',
                    'rating-star-active',
                    'fa',
                    'fa-star',
                    { 'fa-2x': _vm.editMode },
                    'mr-1' ],on:{"mouseover":function($event){return _vm.updateRatingStar(i)},"click":function($event){return _vm.setRatingStar(i)}}}):_c('i',{key:i,class:[
                    'rating-star',
                    'fa',
                    'fa-star-o',
                    { 'fa-2x': _vm.editMode },
                    'text-secondary',
                    'mr-1' ],on:{"mouseover":function($event){return _vm.updateRatingStar(i)},"click":function($event){return _vm.setRatingStar(i)}}})]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }