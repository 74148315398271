<template>
    <div v-if="materialTester" class="material-tester-item">
        <div class="material-img-container"><img :src="imgsrc + materialTester.profile_img" /></div>
        <div class="material-details-container">
            <div style="flex: 1">
                <div class="material-detail-item">
                    <div class="material-detail-label">Name:</div>
                    <div>{{ materialTester.name }}</div>
                </div>
                <div class="material-detail-item">
                    <div class="material-detail-label">City:</div>
                    <div>
                        {{
                            (materialTester.city && materialTester.city.city_name) ||
                                "Not Available"
                        }}
                    </div>
                </div>
                <div class="material-detail-item">
                    <div
                        class="material-detail-label small my-2"
                        v-if="!materialTester.average_rating"
                    >
                        Not Rated
                    </div>
                    <div v-else>
                        <div class="small my-2">{{ materialTester.average_rating }} Stars</div>
                        <div class="small my-2">
                            <RatingStars :rating="materialTester.average_rating" />
                        </div>
                    </div>
                </div>
                <div
                    class="material-detail-item"
                    v-if="
                        materialTester.expertize &&
                            materialTester.expertize.toLowerCase() !== 'null'
                    "
                >
                    <div class="material-detail-label">Expertize:</div>
                    <div>{{ materialTester.expertize }}</div>
                </div>
            </div>

            <div class="d-flex my-2">
                <button
                    class="primary-button request-btn mr-2 bg-primary"
                    @click="viewProfile(materialTester)"
                >
                    View Profile
                </button>
                <div>
                    <button
                        class="primary-button request-btn"
                        @click="sendRequest"
                        v-if="!materialTester.request"
                    >
                        Send Request <i class="fa fa-plus mx-1" />
                    </button>
                    <button
                        class="primary-button request-btn cancel-request-btn"
                        @click="cancelRequest"
                        v-else
                    >
                        Cancel Request <i class="fa fa-times mx-1" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import request from "../../apis/request"
import RatingStars from "../Rating/RatingStars.vue"
import { mapState } from "vuex"

export default {
    props: {
        materialTester: {
            type: Object,
        },
        productId: {
            type: Number,
        },
    },
    data() {
        return {
            imgsrc: axios.defaults.uploadURL,
        }
    },
    components: {
        RatingStars,
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
    },
    methods: {
        viewProfile(materialTester) {
            //
            this.$router.push({ name: "profile", params: { id: materialTester.id } })
            // alert("Open web profile")
        },
        async sendRequest() {
            try {
                const data = {
                    user_id: this.user.id,
                    material_tester_id: this.materialTester.id,
                    product_id: this.productId,
                }
                const res = await request.post("/material-tester-requests", data)
                this.$toasted.success(res.data.message)
                await this.updateMaterialTester(res.data.materialTester)
            } catch (err) {
                console.log(err)
                this.$toasted.error(err.response.data.message)
            }
        },
        async cancelRequest() {
            try {
                const res = await request.post(
                    "/material-tester-requests/delete/" + this.materialTester.request.id
                )
                this.$toasted.success(res.data.message)
                await this.updateMaterialTester(res.data.materialTester)
            } catch (err) {
                console.log(err)
                this.$toasted.error(err.response.data.message)
            }
        },
        async updateMaterialTester(newMaterialTester) {
            this.$emit("update", newMaterialTester)
        },
    },
}
</script>

<style lang="scss" scoped>
.material-tester-item {
    display: flex;
    margin: 20px 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

    .request-btn {
        min-width: 100px;
        margin: 0;
        display: block;
        align-self: flex-start;
        padding: 5px 10px;
    }

    .cancel-request-btn {
        background: rgb(128, 127, 127);
    }
    img {
        width: 100%;
        max-width: 200px;
    }
    .material-img-container {
    }
    .material-details-container {
        padding: 15px;
        display: flex;
        flex-direction: column;

        .material-detail-item {
            display: flex;
            text-transform: capitalize;

            .material-detail-label {
                padding-right: 10px;
                min-width: 80px;
            }
        }
    }
}
@media (hover: hover) {
    .cancel-request-btn {
        &:hover {
            background: rgb(69, 69, 69);
        }
    }
}
@media screen and (max-width: 468px) {
    .material-tester-item {
        flex-direction: column;

        .material-img-container {
            flex: 1;
            img {
                max-width: 500px;
            }
        }
        .material-details-container {
            flex: 2;
        }
    }
}
</style>
