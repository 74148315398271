<template>
    <div class="paginator" v-if="totalPages > 1">
        <button
            class="pagination-btn"
            style="width: 100px; margin-right: 10px"
            @click="updatePage(page - 1)"
        >
            Prev
        </button>
        <template v-for="i in totalPages">
            <button
                :class="['pagination-btn', 'circular-btn', { active: i === page }]"
                :key="i"
                style="min-width: 50px; margin-right: 10px"
                @click="updatePage(i)"
            >
                {{ i }}
            </button>
        </template>
        <button class="pagination-btn" style="width: 100px;" @click="updatePage(page + 1)">
            Next
        </button>
    </div>
</template>

<script>
import scrollTop from "./scrollTop"

export default {
    components: {},
    emits: ["update"],
    props: {
        page: {
            type: Number,
            default: 1,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        totalResults: {
            type: Number,
            default: 110,
        },
    },
    data() {
        return {}
    },
    watch: {
        page(e) {
            scrollTop()
            this.$router.push({
                name: this.$route.name,
                query: {
                    ...this.$route.query,
                    page: e,
                },
            })
        },
        totalResults(e) {
            this.emitPageUpdate({
                totalPages: this.totalPages,
            })
        },
    },
    mounted() {
        let page = 1
        scrollTop()

        // read page from query if exists
        if (this.$route.query.page) {
            page = Number(this.$route.query.page)
        }

        this.emitPageUpdate({
            page,
        })
    },
    methods: {
        updatePage(i) {
            if (i > 0 && i <= this.totalPages) {
                this.emitPageUpdate({
                    page: i,
                    totalPages: this.totalPages,
                })
            }
        },
        emitPageUpdate(data) {
            this.$emit("update", data)
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalResults / this.itemsPerPage)
        },
    },
}
</script>

<style lang="scss" scoped>
$color: #8c0526;
$color2: #ddd;

.paginator {
    margin: 30px 0;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .pagination-btn {
        background: $color;
        color: $color2;
        border: none;
        cursor: pointer;
        border-radius: 10px;

        &:hover {
            background: $color2;
            color: $color;
        }

        &.circular-btn {
            color: $color;
            background: $color2;

            &:hover,
            &.active {
                background: $color;
                color: $color2;
            }
        }
    }
}
</style>
